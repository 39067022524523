import React from 'react';
import withProtectedV2 from '@repo/config/withProtectedV2';
import { useAppSelector } from '@repo/redux/hooks';
import { selectProfile } from '@repo/redux/profileSlice';

export const getServerSideProps = withProtectedV2(async () => {
  return {
    props: {},
  };
});

export default function Home() {
  const profile = useAppSelector(selectProfile);

  console.log('profile', profile);

  return <div>Dashboard</div>;
}
